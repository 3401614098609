import { mapState } from 'vuex'

export default {
    name: 'Footer',

    data: () => ({
        itemsFooter: {},
        textColor: '',
        persistentFooter: true
    }),

    created(){
        window.scrollTo(0,0);
        this.itemsFooter = this.DATACUSTOM.text.navbar
        this.getTextColor(this.DATACUSTOM.colors.backgroundFooterDark);
        this.persistentFooter = !sessionStorage.getItem("hidePersistentFooter");
    },
    methods: {
        hexToRgb(hex) {
            const array = [
                `0x${hex[1]}${hex[2]}`,
                `0x${hex[3]}${hex[4]}`,
                `0x${hex[5]}${hex[6]}`
            ]

            return [array[0] | 0, array[1] | 0, array[2] | 0]
        },
        hidePersistentFooter(){
            this.persistentFooter = false;
            sessionStorage.setItem('hidePersistentFooter',true);

        },
        getTextColor(hexColor) {
            const rgbColor = this.hexToRgb(hexColor)

            const soma = rgbColor.reduce((acc, cv) =>
                Number.parseInt(`${acc}`) + Number.parseInt(`${cv}`))

            this.textColor = soma < 382 ? '#fff' : '#000'
        }
    },
    computed: {
        ...mapState(['establishment'])
    }
}