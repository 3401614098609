import { mapState, mapActions } from 'vuex'
import { formatPrizePoints, getSlugFromRoute } from '../../../../../assets/js/Utils'
import DeliveryAddresses from "../../../delivery_addresses/DeliveryAddresses.vue";
import PrizeDetails from '../prize_details/PrizeDetails.vue';

export default {
  props: { value: Object, requestDeliveryAddress: Boolean },

  components: { DeliveryAddresses, PrizeDetails },

  data: () => ({
    loading: false,
    qtd: 1,
    selectedDeliveryAddress: null,
    images: [],
  }),

  watch: {
    value() {
      this.loadImages()
    }
  },

  created() {
    this.updateSelectedDeliveryAddress();
    this.loadImages();
  },

  mounted() {
    if(this.requestDeliveryAddress && !this.selectedDeliveryAddress)
      document.getElementById("change-delivery-address").click();
  },

  methods: {
    ...mapActions(["actionGetBalanceCliente"]),

    async loadImages() {
      this.images = [this.value.imagemPrincipal]
      const resp = await this.MODULOS.prize.getPrize(this.value.id_produto)
      resp.prize.imagensAdicionais.forEach(e => e != null && this.images.push(e))
    },

    previousRedemptionStep() {
      this.$emit("previous-redemption-step");
    },

    async redemptionPrize() {
      if (!this.user) {
        return
      }

      this.loading = true

      const resp = await this.MODULOS.redemption.redemptionPrize({ 
        quantidade: this.qtd,
        id_premio: this.value.id_produto,
        endereco_entrega_id: this.user.selectedDeliveryAddress?.id_endereco_entrega
      })

      if (resp.status == 200) {
        this.actionGetBalanceCliente()
        this.$router.push({ path: `/${getSlugFromRoute(this.$route.path)}/voucher/${resp.resgate.id_pedido}` })
        this.$toasted.global.success({ msg: "Prêmio resgatado! Imprima e efetue a troca!" })
      }
      if(resp.status == 500){
        this.$toasted.global.error({ msg: resp.mensagem })
      }

      this.loading = false
    },

    formatPrizePoints,

    updateSelectedDeliveryAddress() {
      this.selectedDeliveryAddress = this.user?.selectedDeliveryAddress ?? null;
    },

    getSelectedDeliveryAddressSummary() {
      if(!this.selectedDeliveryAddress) return "";
      const {rua, numero, cidade, estado} = this.selectedDeliveryAddress; 
      return `${rua}, ${numero} - ${cidade} - ${estado}`;
    },

    deliveryAddressIsPending() {
      return this.requestDeliveryAddress && !this.selectedDeliveryAddress;
    },
  },

  computed: {
    ...mapState(["user", "establishment"]),
  },
};